<template>
  <b-overlay :show="show" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
          <!-- Login v1 -->
          <b-card class="mb-0">
            <a href="/">
              <h2 class="font-weight-bolder text-center">
                AK Admin
              </h2>
            </a>

            <!-- form -->
            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form" @submit.prevent="login">

                <!-- email -->
                <b-form-group>
                  <label for="Username">ชื่อผู้ใช้งาน</label>
                  <validation-provider #default="{ errors }" name="Username" vid="Username" rules="required">
                    <b-form-input id="Username" v-model="userEmail" :state="errors.length > 0 ? false : null"
                      name="Username" />
                    <small class="text-danger">{{ errors[0] ? 'กรอกชื่อผู้ใช้งานให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <label for="login-password">รหัสผ่าน</label>
                  <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                        class="form-control-merge" :type="passwordFieldType" name="login-password"
                        @keydown.enter.prevent="GetOTP" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] ? 'กรอกรหัสผ่านให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                    <span>จดจำฉันไว้ในระบบ</span>
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button v-if="nextstep === 0" variant="primary" block :disabled="invalid" @click="GetOTP()">
                  ขอ OTP
                </b-button>
                <b-form-group v-if="nextstep === 1" label="OTP" label-for="OTP">
                  <p style="color: red">
                    ref : {{ ref }}
                  </p>
                  <validation-provider #default="{ errors }" name="OTP" vid="OTP" rules="required">
                    <b-form-input id="OTP" v-model="OTP" :state="errors.length > 0 ? false : null" name="OTP"
                      placeholder="กรุณากรอก OTP" @keydown.enter.prevent="CheckOTP" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button v-if="nextstep === 1" variant="primary" block :disabled="invalid" @click="CheckOTP()">
                  เข้าสู่ระบบ
                </b-button>
              </b-form>
            </validation-observer>

            <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
          </b-card>
          <!-- /Login v1 -->
        </div>
      </div>

      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAACtBAMAAAC0KMWCAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAVUExURUdwTPHx9vDx9vDx9vHy9/Pz+e/w9XrTQnAAAAAGdFJOUwB7ptFPJqEDGaYAAAKASURBVHja7d3NacNAFIVRb1KAUoJUgkB7g0qIW1D/JcSEkEVwyJ/BulfnlKB8DO/NGHI6QYHLyzoM4zT7EuR6uqzr8HzNePvgoxB4GJ+HcZyX7Qafh5DD+H2muJmxoEmZKc7XmWKet5/yzciaKQRN6oK3CZq0w/jXM4WgSV3wBE3Vgido9rjgDX9d8ARN1YInaHaQ8fyAjAXNbu8pBE3VPYWg6R+NBU3qPYWgOeZhLGi+P4zffhUUl7Gg+TxTTCEzhaD5YqbY022boPnPTLFsrfyNLXiCJuEwLp0pBH2s07hpwRP04S8qtsPTQvxFxbTIWND5FxXaFXT+RYXDWNAFjx8yFrQFT9CYKQSNBU/QRz+MzRSCzj+MLXiCNlMg6P284MlY0AWHsZlC0OELnsNY0F7wEPROXvBkLOj8FzwzhaAteAjaCx6CvseCJ2NBmykQtAUPQd/jV0EyFnTBgmemELQFD0E//AXPgkdy0BY8CoJ220ZB0BY88oO24FEQtJmC/KAteBQE7QWP/KC94FEQtJmCgqC94JEftMOYgqAL/kc0grbgkR+0FzwKgvaCR37QFjwKgvaCR0HQFjw6gjZTUBW0T4CgQdAgaBA0ggZBg6BB0CBoBA2CBkGDoEHQCBoEDYIGQYOgETQIGgQNggZBI2gQNAgaBA2CRtAgaBA0CBoEjaBB0CBoEDSCBkGDoEHQIGgEDYIGQYOgQdAIGgQNggZBg6ARNAgaBA2CBkEjaBA0CBoEDYJG0CBoEDQIGgSNoEHQIGgQNAgaQYOgQdAgaAQNggZBg6BB0AgaBA2CBkGDoBE0CBoEDYIGQSNoEDQIGgQNgkbQIGgQNAgaBE2rV8fAx8SI153KAAAAAElFTkSuQmCC"
        alt="" class="img-login">
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  // BImg,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCard,
  BForm,
  BButton,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import BUID from 'uniquebrowserid'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BImg,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BCard,

    BOverlay,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ref: null,
      show: false,
      OTP: '',
      nextstep: 0,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      ag: 0,
      urlori: window.location.origin,
      AgLog: {
        ip: null,
        latitude: '',
        longitude: '',
        buid: new BUID().completeID(),
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // this.url = this.$route.query.url
    this.getagid()
    // this.GetLocation()
    this.GetIp()
  },
  methods: {
    // GetLocation() {
    //   if ('geolocation' in navigator) {
    //     navigator.geolocation.getCurrentPosition(position => {
    //       const { latitude } = position.coords
    //       const { longitude } = position.coords
    //       this.AgLog.latitude = latitude
    //       this.AgLog.longitude = longitude
    //     })
    //   }
    // },
    // async GetIp() {
    //   await axios
    //     .get('https://api.ipify.org')
    //     .then(response => {
    //       this.AgLog.ip = response.data
    //     })
    //     .catch(error => console.log(error))
    // },
    getagid() {
      this.$http
        .get(`/agent/getEndpoint?agent_id=${process.env.VUE_APP_AGENT_ID}`)
        .then(response => {
          this.ag = response.data
          this.show = false
        })
        .catch(error => console.log(error))
    },
    GetOTP() {
      this.show = true
      const obj = {
        name: this.userEmail,
        agent_id: this.ag.agent_id,
        ip: this.AgLog.ip,
      }
      this.$http
        .post('/otplogin/store', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data.ref)
          this.ref = response.data
          this.nextstep = 1
          this.show = false
        })
        .catch(error => console.log(error))
    },
    CheckOTP() {
      const obj = {
        name: this.userEmail,
        otps: this.OTP,
        agent_id: this.ag.agent_id,
      }
      this.$http
        .post('/otplogin/check', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.login()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'OTP ไม่ถูกต้อง',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
    // login() {
    //   this.$refs.loginForm.validate().then(success => {
    //     if (success) {
    //       useJwt
    //         .login({
    //           email: this.userEmail,
    //           password: this.password,
    //           agent_id: this.ag.agent_id,
    //         })
    //         .then(response => {
    //           const userData = response.data
    //           useJwt.setToken(response.data.token)
    //           useJwt.setRefreshToken(response.data.refreshToken)
    //           localStorage.setItem('userData', JSON.stringify(userData))
    //           this.$ability.update(userData.ability)
    //           if (userData.role === 'zean') {
    //             this.LogAdminZean()
    //           } else {
    //             this.LogAdmin()
    //           }
    //           // ? This is just for demo purpose as well.NODE_OPTIONS=--openssl-legacy-provider vue-cli-service serve
    //           // ? Because we are showing eCommerce app's cart items count in navbar
    //           // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

    //           // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
    //           this.$router
    //             .push(getHomeRouteForLoggedInUser(userData.role))
    //             .then(() => {
    //               this.$toast({
    //                 component: ToastificationContent,
    //                 position: 'top-right',
    //                 props: {
    //                   title: 'Welcome to Image Dashboard',
    //                   icon: 'CoffeeIcon',
    //                   variant: 'success',
    //                   text: 'You have successfully logged ',
    //                 },
    //               })
    //             })
    //             .catch(error => {
    //               this.$refs.loginForm.setErrors(error.response.data.error)
    //             })
    //         })
    //         .catch(error => {
    //           this.$toast({
    //             component: ToastificationContent,
    //             position: 'top-right',
    //             props: {
    //               title: 'ไม่สามารถเข้าระบบได้ในขณะนี้',
    //               icon: 'CoffeeIcon',
    //               variant: 'danger',
    //               text: error.response.data.message,
    //             },
    //           })
    //         })
    //     }
    //   })
    // },

    getAgent() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log('xxx')
          store.commit('app/SET_AGENT', response.data)

          // console.log(response.data)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          console.log(error)
        })
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          axios.post(`${this.ag.endpoint}/auth/login`, {
            email: this.userEmail,
            password: this.password,
            agent_id: this.ag.agent_id,
          })
            .then(response => {
              console.log(response)
              const userData = response.data
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)
              if (userData.role === 'zean') {
                this.LogAdminZean()
              } else {
                this.LogAdmin()
              }

              this.getAgent();
              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .push(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome to Image Dashboard',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged ',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ไม่สามารถเข้าระบบได้ในขณะนี้',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        }
      })
    },
    LogAdmin() {
      const params = {
        on_page: 'login',
        ip: this.AgLog.ip,
        latitude: this.AgLog.latitude,
        longitude: this.AgLog.longitude,
        buid: this.AgLog.buid,
      }
      this.$http.get('/admin/log', { params })
    },
    LogAdminZean() {
      const params = {
        on_page: 'login',
        ip: this.AgLog.ip,
        latitude: this.AgLog.latitude,
        longitude: this.AgLog.longitude,
      }
      this.$http.get('/admin/log/zean', { params })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.bg {
  background-image: url('/bg2.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.card {
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(94, 86, 105, .1);
  background-color: rgb(255, 255, 255);
  padding: .9375rem .875rem;
}

label {
  font-size: 14px;
  font-weight: 400;
}
</style>
